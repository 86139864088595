<template>

  <div class="home">

    <component v-bind:is="rootobjecttype" v-bind:id="rootobjectid" ></component>
    <bulmaModal v-bind:buttons="['Save', 'Cancel']" v-on:click="manageModal($event)"
        v-for="status in $store.state.process_stack" v-bind:key="status.id" v-bind:title="status.title" 
        >
      <component v-bind:is="status.type" v-bind:id="status.id" ></component>
    </bulmaModal>

  </div>
  
</template>

<script>
// @ is an alias to /src
// import numeral from 'numeral'
import apiclient from '@/apiclient.js'
import gnetMixLib from '@/mixin.js'

import configfile from '@/config.js'

export default {
  name: 'Home',
  mixins: [gnetMixLib],
  components: {
  },
  props: {
    process: String,
    verb: String,
    recordid: String,
  },
  watch: { 
    '$route': function() {
      console.log("routeChanged");
      console.log(this.$route.path);
      console.log(this.$route.params);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      if (this.process) this.$store.commit( 'SET_GLOBAL_STATE_PROCESS', this.process );
      if (this.verb) this.$store.commit( 'SET_GLOBAL_STATE_VERB', this.verb );
      if (this.recordid) {
        console.log('recordid is present', this.recordid);
        console.log('getRecordState', this.$store.getters.getRecordState);
        
        // TODO: quando va cambiato il record? se non cambia il recordid potrebbe cambiare il verbo, tipo un cancel su edit che va annullato
        this.$store.commit('SET_RECORDS_ID', { id: this.process, data: this.recordid });
        if (this.$store.getters.getRecordState=='TOLOAD' || !this.$store.getters.getRecordState) {
          this.loadRecord();
          this.$store.commit('SET_RECORDS_STATE', { id: this.process, state:'READY' });
        }
      }
    },
    message: function(newVal) { // bus message
      if (newVal && newVal.message) this.doMessage(newVal);
    },
  },
  computed: {
    message: function() { // bus message
      return { message: this.$store.state.message, payload :this.$store.state.message_payload };
    },
    pageid: function() {
      let pconfig=this.$store.getters.getProcess(this.process);
      console.log("pconfig", pconfig)
      if (!pconfig || !pconfig.verbs) {
        this.$store.commit( 'SET_GLOBAL_STATE_PAGEID', "p404" );
        return "p404";
      }
      
      let vconfig=pconfig.verbs.find( (v) => { if (this.verb) return v.id==this.verb; else return v.id=="default"; } );
      console.log("vconfig", vconfig);
      if (!vconfig) {
        this.$store.commit( 'SET_GLOBAL_STATE_PAGEID', "p404" );
        return "p404";
      }

      this.$store.commit( 'SET_GLOBAL_STATE_PAGEID', vconfig.pageid );
      // console.log("pageid", vconfig.pageid);

      return vconfig.pageid;
    },
    pageconfig: function() {
      if (!this.pageid) {
        console.log("pageid null");
        return {   "id": "", "initialid": "" };
//        return this.$store.getters.getPage('home');
      }
      let pc=this.$store.getters.getPage(this.pageid);
      if (!pc) {
        console.log("pageconfig null");
        return this.$store.getters.getPage('home');
      }
      return pc;
    },
    rootobjectid: function() {
      return this.pageconfig.initialid;
    },
    rootobjecttype: function() {
      return this.$store.getters.getObject(this.rootobjectid).type;
    }
  },
  data: function() {
    return {
      editmode:true,
      showheader: true,
      debug:false,
      lab:false,
    };
  },
  created: async function() {
   this.$store.commit( 'SET_GLOBAL_CONFIG', configfile );
    // await this.$store.dispatch('getConfigFromMeta');

//    console.log('home', this.$store.getters.getPage('home') )
  },
  mounted: async function() {
    if (this.debug) console.log('home mounted:', this.process, this.verb, this.recordid);   
    this.$store.commit( 'SET_GLOBAL_STATE_PROCESS', this.process );
    this.$store.commit( 'SET_GLOBAL_STATE_VERB', this.verb );
    this.$store.commit( 'SET_RECORDS_ID', { id: this.process, data: this.recordid });

    console.log('process is', this.process);
    console.log('recordid is', this.recordid);
    console.log('getRecordState', this.$store.getters.getRecordState);

    if (this.$store.getters.getRecordState=='TOLOAD' || !this.$store.getters.getRecordState) {
      await this.loadRecord();
      this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'READY' });
    }
    if (this.verb=='e') this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'EDIT' });
  },
  methods: {
    routeChanged: function() {
      console.log("routeChanged");
      console.log(this.$route.path);
      console.log(this.$route.params);
    },
    doMessage: async function(message) { // bus message
      console.log('doMessage:',message.message);
      if (this.$store.getters.getRecordState=='TOLOAD') {
        this.loadRecord();
      }

      let timestamp=Date.now();
      let eventTemplateId=1350905;
      let objectId=56601;
      let tokens={
        showroom_visit_date: Date.now(),
        showroom_visit_feedback: 5
      }

      switch (message.message) {
        case 'test_event': 
          apiclient.createcustomevent(this.$store.getters.connection_id, timestamp, eventTemplateId, objectId, tokens)
          break;
        case 'new_record':
          this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'NEW' });
          if (message.payload)
            this.$router.push('/'+message.payload+'/n');
          else
            this.$router.push('/'+this.process+'/n');
          break;
        case 'edit_record':
          console.log('prima');
          await this.loadRecord();
          console.log('dopo');
          this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'EDIT' });
          this.$router.push('/'+this.process+'/e/'+this.recordid);
          break;
        case 'save_record':
          await this.saveRecord();
          this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'READY' });
          if (!this.recordid)
            this.$router.push('/'+this.process+'/d/'+101);
          else
            this.$router.push('/'+this.process+'/d/'+this.recordid);
          break;
        case 'cancel_record':
          if (this.recordid) {
            this.loadRecord();
            this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'READY' });
            this.$router.push('/'+this.process+'/d/'+this.recordid);
          }  else {
            this.$store.commit( 'SET_RECORDS_STATE', { id: this.process, state:'' });
            this.$router.push('/'+this.process);
          }
          break;
        case 'delete_record':
          // goto process list
          this.$router.push('/'+this.process);
          break;
        case 'goto_home':
          this.$router.push('/');
          break;
        case 'goto_tasks':
          var p=this.$store.commit('POP_PROCESS_STATUS');
          console.log(p);
          break;
        case 'goto_search':
          var p={ id:'deals', type:'bulmaRows' };
          this.$store.commit('PUSH_PROCESS_STATUS', p);
          break;
        case 'activate_lab':
          var p={ id:'deals', type:'bulmaRows' };
          this.$store.commit('PUSH_PROCESS_STATUS', p);
          break;
        case 'activate_buddy':
          var p={ id:'buddy', type:'bulmaBuddy' };
          this.$store.commit('PUSH_PROCESS_STATUS', p);
          break;
        case 'deactivate_lab':
          var p=this.$store.commit('POP_PROCESS_STATUS');
          console.log(p);
          
          break;
        case 'goto_back':
          this.$router.go(-1);
          break;
        default:
          console.log('unknown message:', message.message);
          break;
      }
      this.$store.commit( 'CLEAR_MESSAGE');
    },

    loadRecord: async function() {
      console.log("loadRecord!", this.process);

      let pconfig=this.$store.getters.getProcess(this.process);
      if (!pconfig) return;
      if (!this.$store.state.database.has(pconfig.table)) return;
      console.log(pconfig);

      let thetable=this.$store.state.database.get(pconfig.table);
      console.log(thetable);
      
      let p=[]
      thetable.forEach( f => {
        p.push(f.name);
      });

      this.$store.commit('SET_RECORDS_DATA', { id: this.process, data:{} });

      let assoc=['companies', 'contacts', 'company'];
      await this.$store.dispatch('retrieve_record', { id: this.process, object: pconfig.table, recordId: this.recordid, properties: p, associations: assoc })
    },
    saveRecord: async function() {
      console.log("saveRecord!");
      
      let pconfig=this.$store.getters.getProcess(this.process);
      if (!this.recordid)
        await this.$store.dispatch('create_record', { object: pconfig.table, properties: this.$store.getters.getRecordData });
      else
        await this.$store.dispatch('update_record', { object: pconfig.table, recordId: this.recordid, properties: this.$store.getters.getRecordData });
    },
  },
}
</script>
<style scoped>
</style>

