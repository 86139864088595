<template lang="html">

    <bulmaModal v-bind:title="'SalesBuddy'" v-bind:buttons="[]" v-on:click="doAction"  v-bind:id="id" >

        <span v-if="false">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <span class="tag is-black">Black</span>
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
              </li>
              <li>
                <span class="tag is-light">light</span>
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
              </li>
              <li>
                <span class="tag is-white">white</span>
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
              </li>
              <li>
                <span class="tag is-primary">primary</span>
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
              </li>
              <li>
                <span class="tag is-link">link</span>
                <span class="icon"><i class="fas fa-arrow-right"></i></span>
              </li>
            </ul>
          </nav>
        </span>

        <nav class="level">
          <div class="level-left">
            <div class="buttons">
              <template v-for="(c, n_c) in choose" >
                <button class="button is-primary" v-on:click="action(c)">{{ n_c+1 }}&nbsp;{{ c.name }}</button>
              </template>
              <button class="button is-dark" v-on:click="back" v-if="sequence.length>1">{{ 0 }}&nbsp;back</button>
            </div>
          </div>
          <div class="level-right">
            <button class="button is-info" v-on:click="speechStart" v-if="!speechok">
              <span class="icon"><i class="fas fa-microphone"></i></span> &nbsp; Talk
            </button>
            <button class="button is-ghost" v-on:click="speechStop" v-if="speechok">
              <span class="icon"><i class="fas fa-microphone-slash"></i></span> &nbsp; Stop
            </button>
          </div>
        </nav>

        <nav class="level">
          <div class="level-left">{{ msg }}</div>

          <div class="level-right">
            <div class="buttons">
              <button class="button" >
                <span v-for="element in sequence"  v-bind:key="element.name"> {{ element.name }}&nbsp; </span>
              </button>
            </div>
          </div>

        </nav>

    </bulmaModal>

</template>

<script>

export default {
  name: 'bulmaBuddy',
  components: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
      status: 'root',
      sequence: [ ],
      choose: [ ],
      matrix: {},
      SpeechRecognition: {},
      SpeechGrammarList: {},
      SpeechRecognitionEvent: {},
      recognition: {},
      msg:'',
      speechok:0,
      listener:{}
    }
  },
  mounted: function() {
    this.sequence=[];
    this.choose=[];

    this.loadMatrix();

    this.action({state:'root'});
    this.speechConfig();
  },
  beforeDestroy: function() {
    document.removeEventListener('keydown', this.tasto);
  },
  async created() {
    this.listener=document.addEventListener('keydown', this.tasto);
  },
  methods: {
    doAction: function(event) {
      console.log(event);
      if (event<=0) {
        this.$store.commit('POP_PROCESS_STATUS');
        return;
      }
      this.speechStart();
      // this.$emit('click', event);
    },
    speechConfig: function() {

      let SpeechRecognition =  window.SpeechRecognition || window.webkitSpeechRecognition;
      let SpeechGrammarList =  window.SpeechGrammarList || window.webkitSpeechGrammarList;
      // let SpeechRecognitionEvent =  window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

      let words=[];
      for (let step in this.matrix) {
        this.matrix[step].choose.forEach( (c) => {
          words.push(c.name);
        })
      }
      words.push('back');

      const grammar = `#JSGF V1.0; grammar colors; public <color> = ${words.join( " | ")};`;
      
      this.recognition = new SpeechRecognition();
      const speechRecognitionList = new SpeechGrammarList();
      speechRecognitionList.addFromString(grammar, 1);

      this.recognition.grammars = speechRecognitionList;
      this.recognition.continuous = false;
      this.recognition.lang = "en-US";
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 1;
      this.recognition.onresult =this.speechResult;
      this.recognition.onspeechend =this.speechStop;
      this.recognition.onnomatch =this.speechNoMatch;
      this.recognition.onerror =this.speechError;
    },
    speechStart: function() {
      this.recognition.start();
      console.log("Ready to receive a color command.");
      this.msg='';
      this.speechok=1;
    },
    speechStop: function() {
      this.recognition.stop();
      console.log("Stop listening.");
      // this.msg='';
      this.speechok=0;
    },
    speechResult: function(event) {
      console.log('Results1:', event.results );
      console.log('Results2:', event.results[0][0] );
      let res=event.results[0][0];
      if (res.confidence>0.7) {
       let word=res.transcript;
        this.msg=word;
        this.speechok=1;
        if (word=='back') {
          this.back();
          return;
        }

        word=word.toUpperCase();
        let found;
        do {
          found=false
          this.choose.forEach( (c) => {
            let token=c.name.toUpperCase();
            console.log("token",token);
            if (word.indexOf(token)>=0) {
              console.log("found:",token);
              this.action(c);
              found=true;
            }
          })
        } while (found)

      } else {
        this.speechok=0;
        this.msg="not sure:"+res.transcript+" "+res.confidence;
      }
    },
    speechNoMatch: function(event) {
      console.log('NO Match:', event );
      this.speechok=0;
    },
    speechError: function(event) {
      console.log('Error:', event );
      this.speechok=0;
    },
    tasto: function(event) {
      console.log('tassssssto',event.key);
      if (event.key=='0') { 
        this.back();
        return ;
      }
      if (event.key>0) this.action(this.choose[ parseInt(event.key)-1] )
    },
    back: function(event) {
      console.log(event);
      let c1=this.sequence.pop();
      let c2=this.sequence.at(-1);
      let s=this.matrix[c2.state];
      this.choose=s.choose;
    },
    action: function(event) {
      console.log(event);
      if (event.state=='cancel') {
        this.sequence=[];
        this.action({state:'root'})
        return;
      }
      if (event.state=='save') {
        this.$store.commit('POP_PROCESS_STATUS');
        return;
      }
      this.sequence.push(event);
      let s=this.matrix[event.state];
      this.choose=s.choose;
    },
    loadMatrix: function() {
      this.matrix={
        'root': { choose: [ 
            { name:'create', state:'root-create'}, 
            { name:'briefme', state:'root-briefme'}, 
            { name:'search', state:'root-search'}, 
          ], 
        },
        'root-create': { choose: [ 
          { name:'deal', state:'deal-type'}, 
          { name:'lead', state:'create-lead'}, 
          { name:'task', state:'create-task'},
          ], 
        },
        'deal-type': { choose: [ 
          { name:'CS', state:'deal-brand', data: {"brand":"cs"} }, 
          { name:'REGIONAL', state:'deal-brand', data: {"brand":"regional"}}, 
          { name:'NATIONAL', state:'deal-brand', data: {"brand":"national"}}, 
          ], 
        },
        'deal-brand': { choose: [ 
          { name:'FTI', state:'deal-confirm', data: {"brand":"fti"} }, 
          { name:'LEA', state:'deal-confirm', data: {"brand":"lea"}}, 
          { name:'BOTH', state:'deal-confirm', data: {"brand":"fti&lea"}}, 
          ], 
        },
        'root-briefme': { choose: [ 
            { name:'today meetings', state:'briefme-today'}, 
            { name:'my performance', state:'briefme-performance'}, 
            { name:'pipeline', state:'briefme-pipeline'}, 
          ], 
        },
        'create-lead': { choose: [ 
          { name:'commercial', state:'lead-confirm'}, 
          { name:'end user', state:'lead-confirm'}, 
          { name:'new distributor', state:'lead-confirm'}, 
          ], 
        },
        'deal-confirm': { choose: [ 
          { name:'confirm', state:'save'}, 
          { name:'cancel', state:'cancel'}, 
          ], 
        },
        'lead-confirm': { choose: [ 
          { name:'confirm', state:'save'}, 
          { name:'cancel', state:'cancel'}, 
          ], 
        },
      }
    },
    startAudioRecording: function() {
      //start recording using the audio recording API
      audioRecorder.start().then(() => { //on success
              console.log("Recording Audio...")    
      }).catch(error => { //on error
        console.log(error);
      });
    },
    StopAudioRecording: function() {
      //stop the recording using the audio recording API
      console.log("Stopping Audio Recording...")
      audioRecorder.stop().then(audioAsblob => {
        console.log("stopped with audio Blob:", audioAsblob);
      })
      .catch(error => {
        console.log(error);
      });
    },
    cancelAudioRecording:function() {
        console.log("Canceling audio...");
        audioRecorder.cancel();
    }

  }
}

var audioRecorder = {
  audioBlobs: [],
  mediaRecorder: null,
  streamBeingCaptured: null,

  start: function () {
    //Feature Detection
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
    }
    else {
      //create an audio stream
      return navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        audioRecorder.streamBeingCaptured = stream;
        audioRecorder.mediaRecorder = new MediaRecorder(stream); 
        audioRecorder.audioBlobs = [];

        audioRecorder.mediaRecorder.addEventListener("dataavailable", event => { audioRecorder.audioBlobs.push(event.data);});
        audioRecorder.mediaRecorder.start();
      });
    }
  },
  stop: function () {
    return new Promise(resolve => {
      let mimeType = audioRecorder.mediaRecorder.mimeType;
      audioRecorder.mediaRecorder.addEventListener("stop", () => {
        let audioBlob = new Blob(audioRecorder.audioBlobs, { type: mimeType });
        resolve(audioBlob);
      });
      audioRecorder.mediaRecorder.stop();
      audioRecorder.stopStream();
      audioRecorder.resetRecordingProperties();
    });
  },

  stopStream: function() {
    audioRecorder.streamBeingCaptured.getTracks().forEach(track => track.stop());
  },
  resetRecordingProperties: function () {
    audioRecorder.mediaRecorder = null;
    audioRecorder.streamBeingCaptured = null;
  },
  /** Cancel audio recording*/
  cancel: function () {
    audioRecorder.mediaRecorder.stop();
    audioRecorder.stopStream();
    audioRecorder.resetRecordingProperties();
  }
}


</script>

<style scoped>
</style>
