<template lang="html">

  <div class="field is-horizontal" v-bind:id="id">
    <div class="field-label is-small" style="padding-top: 12px;">
      <label class="label">{{ data.label }}</label>
    </div>
    <div class="field-body">

      <div class="field" v-if="isText(data.type)">
        <div class="control is-small">
          <input v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" v-if="data.type!='textarea'" class="input" v-bind:type="data.type" v-bind:placeholder="data.placeholder" v-bind:class="{ 'is-success': data.success, 'is-danger': data.fail}">
          <textarea v-on:change="change" v-bind:readonly="readonly" v-bind:value="fieldval" v-if="data.type=='textarea'" class="textarea" v-bind:placeholder="data.placeholder" v-bind:rows="data.rows" v-bind:class="{ 'is-success': data.success, 'is-danger': data.fail}"></textarea>
          <p v-if="data.success || data.fail" class="help" v-bind:class="{ 'is-success': data.success, 'is-danger': data.fail}">{{ data.message }}</p>
        </div>
      </div>

      <label v-if="data.type=='checkbox'" class="checkbox" >
        <input v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" v-bind:type="data.type" >
      </label>

      <div v-if="data.type=='radio'" class="control">
        <label  class="radio" v-for="option in data.options" v-bind:key="option.value">
          <input v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval" v-bind:type="data.type" v-bind:name="data.name" >
          {{ option.label }}
        </label>
      </div>

      <div v-if="data.type=='picklist'" class="select">
        <select v-on:change="change" v-bind:readonly="readonly" v-bind:disabled="disabled" v-bind:value="fieldval">
          <option v-for="option in data.options" v-bind:key="option.value">{{ option.label }}</option>
        </select>
      </div>

      <div class="field" v-if="data.type=='lookup' && disabled">
        <div class="control is-small">
          pppp
          <input v-bind:disabled="disabled" v-bind:value="fieldval" class="input" v-bind:type="'text'" v-bind:class="{ 'is-success': data.success, 'is-danger': data.fail}">
          <p v-if="data.success || data.fail" class="help" v-bind:class="{ 'is-success': data.success, 'is-danger': data.fail}">{{ data.message }}</p>
        </div>
      </div>


    </div>
  </div>

</template>

<script>
/*
'text' 'truncate' 'string' 'picklist'
'number' 'currency' 'decimal' 'date' 'datetime'
'checkbox' 'picklist'
*/

import numeral from 'numeral'
import moment from 'moment'

export default {
  name: 'bulmaField',
  components: {},
  props: {
    id: String,
    data: Object,
  },
  data: function () {
      return {
    }
  },
  watch: {
  },
  computed: {
    fieldval: function() {
      let r=this.$store.getters.getRecordData;
      console.log('val',r);

      if (r && this.data.type!='lookup')  return r[this.data.name];
      if (r && this.data.type=='lookup')  {
        let n=this.data.name.slice(0,this.data.name.length-3)+'__r.Name';
        console.log('name',n);
        return r[n];
      }
      return '';
    },
    disabled: function() {
      return this.$store.getters.getRecordState!="EDIT" && this.$store.getters.getRecordState!="NEW";
    },
    readonly: function() {
      return false;
    },
  },
  mounted: function() {
  },
  filters: {
  },
  methods: {
    change: function(e) {
      console.log("change",e.target.value);
      this.$store.commit('SET_FIELD_VALUE', { fieldname: this.data.name, fieldvalue: e.target.value} );
    },
    isText: function(type) {
      if (type=='text' || type=='password' || type=='email' || type=='tel' || type=='textarea') return true;
      return false
    },
    filter: function(v, type) {
      switch(type) {
        case 'hidden':
          return;
        case 'date':
          if (v) {
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          }
          return v;
        case 'datetime':
          if (v) {
            var n=new moment(v);
            return n.format('DD/MM/YYYY');
          }
          return v;
        case 'decimal':
          if (v)
            return numeral(v).format('0,0');
          return v;
        case 'currency':
          if (v)
            return numeral(v).format('$ 0,0.00');
          return v;
        case 'truncate':
          if (v)
            return v.substr(0,32);
      }
      return v;
    },
    getFieldValue: function(obj, path) {
      // eslint-disable-next-line no-redeclare
      for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
        if (obj)
          obj = obj[path[i]];
      }
      return obj;
    },
    align: function(type) {
      switch(type) {
        case 'currency':
        case 'decimal':
          return 'right';
      }
      return 'left';
    },
  }
}
</script>

<style scoped>
</style>
