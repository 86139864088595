<template lang="html">

<!--
  <nav class="level" v-bind:id="id">
    <div class="level-item level-right buttons" >
      <div v-for="(action, index) in config.actions" v-bind:key="action.name">
        <button class="button is-primary" v-on:click="doAction(action)" v-bind:class="{ 'is-outlined': !action.default}" v-bind:disabled="action.disabled">{{ $t(action.label) }}
          <span class="icon is-small" v-if="action.icon"><i class="fas fa-bold" v-bind:class="'fa-'+action.icon"></i></span>
        </button>
      </div>
    </div>
  </nav>
-->

<nav class="level" v-bind:id="id" >
  <div class="level-item level-left" >
    <h1 class="title">{{ $t(config.title) }}</h1>
  </div>
  <div class="level-item level-right" >
      <button class="button is-loading is-small" v-if="$store.state.busy" >Loading</button>

      <div class="buttons has-addons is-right are-small">

        <button v-for="(action, index) in buttons" v-bind:key="action.name" class="button " v-on:click="doAction(action)" v-bind:class="{ 'is-selected is-link': action.default}" v-bind:disabled="action.disabled">
          <span class="icon is-small" v-if="action.icon"><i class="fas" v-bind:class="'fa-'+action.icon"></i></span>
          <span>{{ $t(action.label) }}</span>
        </button>

      </div>
    </div>
</nav>
  
</template>

<script>

export default {
  name: 'bulmaActions',
  components: {},
  props: {
    id: String,
  },
  data: function () {
      return {
    }
  },
  watch: {
  },
  computed: {
    config: function() {
      return this.$store.getters.getObject(this.id).data;
    },
    buttons: function() {
      let b=[];
      this.config.actions.forEach( (a) => {
        if (this.isValid(a)) {
          if (this.isValidSW(a)) {
            b.push(a)
          }
        }
      })
    return b;
    }
  },
  mounted: function() {
  },
  filters: {
  },
  methods: {
    isValid: function(action) {
      if (!action.states) return true;
      let f=false
      action.states.forEach( s => {
        f = f || (s==this.$store.getters.getRecordState);
      });
      return f;
    },
    isValidSW: function(action) {
      if (!action.conditions) return true;
      let f=false
      action.conditions.forEach( s => {
        f = f || this.$store.getters.getSWState(s);
      });
      return f;
    },
    doAction: function(action) {
      console.log(action.action);
      let m={ message: action.action, payload: this.id};
      this.$store.commit('POST_MESSAGE', m);
    }
  }
}
</script>

<style scoped>
</style>
