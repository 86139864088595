<template lang="html">

<div class="modal is-active" ref="modal">
  <div class="modal-background"></div>
  <div class="modal-card" v-bind:style="modalwidth">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
      <button v-on:click="selected=-1" class="delete" aria-label="close" v-on:click.prevent="$emit('click', -1)"></button>
    </header>

    <section class="modal-card-body">
      <slot></slot>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button" v-on:click="$emit('click', bindex)" v-for="(b, bindex) in buttons" v-bind:class="{'is-success': bindex==0}" v-bind:key="b">{{ b }}</button>
      </div>
    </footer>
</div>
</div>

</template>

<script>

export default {
  name: 'bulmaModal',
  computed: {
    modalwidth: function() {
      if (this.isNarrow) return 'width:80%';
      return '';
    }
  },
  components: {

  },
  props: {
    title: String,
    buttons: Array,
    isNarrow: { type: Boolean, default: true }
  },
  data() {
    return {
    }
  },
  mounted: function() {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
