import Vue from 'vue'
import Vuex from 'vuex'
// import metaremote from '@/metaremote.js'
import apiclient from '@/apiclient.js'
// import { platform } from 'chart.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app: 'SalesPaddy 4.0',
    logo: 'app.png',
    busy: false,
    
    login:false,
    connection:'',

    config: { },
    editmode: { },
    romode: { },

    global_state_process:"", 
    global_state_verb:"", 
    global_state_pageid:"",

    // bus message
    message:"",
    message_payload:{},

    // one record at time
    /*
    record_id: "",
    record_data: { },
    record_assoc: { },
    record_state: "",
    */

    records_id: { },
    records_data: { },
    records_assoc: { },
    records_state: { },
    records_list: { },

    software_states: {},

    database: new Map(),

    process_stack: [],

    /*
      NEW: record empty
      EDIT: record in edit mode
      READY: record loaded
    */
  },
  getters: {
    appname: function(state) { return state.app },

    connection_id: function(state) { 
      if (state.connection) return state.connection 
      let previous=localStorage.getItem('salespaddy_id');
      if (previous) {
        state.connection=previous;
        return state.connection
      }
      return; 
    },
    logged: function(state) { return state.login },

    getConfig: (state) =>  (id) => {
      return state.config[id];
    },
    getEditMode: (state) =>  (id) => {
      return state.editmode[id];
    },
    getROMode: (state) =>  (id) => {
      return state.romode[id];
    },
    getProcess: (state) =>  (id) => {
      if (!state.config.processes) return {}
      return state.config.processes.find( (v) => { if (id) return v.id==id; else return v.id=="default"; } );
    },
    getVerb: () =>  (process, verb) => {
      if (!process.verbs) return {}
      return process.verbs.find( (v) => { if (verb) return v.id==verb; else return v.id=="default"; } );
    },
    getPage: (state) =>  (id) => {
      if (!state.config.pages) return {}
      return state.config.pages.find( (v) => { return v.id==id} );
    },
    getObject: (state) =>  (id) => {
      if (!state.config.objects) return {}
      return state.config.objects.find( (v) => { return v.id==id} );
    },
    getSWState: (state) =>  (name) => {
      return state.software_states[name]
    },
    getRecordsList: (state) =>  (id) => {
      return state.records_list[id];
    },
    getRecordData: function(state) { 
      if (!state.config.processes) return {};
      return state.records_data[state.global_state_process];
    },
    getRecordState: function(state) { 
      if (!state.config.processes) return '';
      return state.records_state[state.global_state_process];
    },
    getRecordId: function(state) {
      if (!state.config.processes) return '';
      return state.records_id[state.global_state_process];
    },
    getRecordAssoc: (state) =>  (id) => {
      return state.records_assoc[id];
    },
    getProcessStatus: function(state) { return state.process_stack.slice(-1) },
  },
  mutations: {
    PUSH_PROCESS_STATUS: function(state, payload) { 
      state.process_stack.push(payload);
    },
    POP_PROCESS_STATUS: function(state) { 
      return state.process_stack.pop();
    },
    SET_FIELD_VALUE: function(state, payload) { 
      if (!state.config.processes) return {};
      state.records_data[state.global_state_process][payload.fieldname]=payload.fieldvalue;
    },
    SET_BUSY: function(state, payload) { state.busy=payload },

    SET_CONNECTION_ID: function(state, payload) { 
      state.connection = payload;
      localStorage.setItem('salespaddy_id', payload);
    },
    SET_LOGIN_STATE: function(state, payload) { state.login = payload; },

    SET_GLOBAL_CONFIG: function(state, payload) { 
      state.config=payload;
      if (state.config.database) {
        state.config.database.forEach( (d) => {
          var fields=new Map();
          d.fields.forEach( (f) => {
            fields.set( f.name, f);
          })
          state.database.set( d.table, fields );
        })
      }
    },
    SET_CONFIG: function(state, payload) { Vue.set(state.config, payload.id, payload.cfg);},
    SET_EDITMODE: function(state, payload) { Vue.set(state.editmode, payload.id, payload.editmode); },
    SET_ROMODE: function(state, payload) { Vue.set(state.romode, payload.id, payload.romode); },

    // set object config
    // payload: id, type, data
    SET_CONFIG_OBJECT: function(state, payload) { 
      let i=state.config.objects.find( (obj) => { return obj.id==payload.id; } );
      if (i>=0) {
        state.config.objects.splice(i, 1);
        state.config.objects.splice(i, 0, payload );
      } else {
        state.config.objects.push( payload );
      }
    },

    // id is the name of the component
    SET_RECORDS_LIST: function(state, payload) { Vue.set(state.records_list, payload.id, payload.data); },

    // id is the name of the component
    SET_RECORDS_ID: function(state, payload) { 
      console.log('SET_RECORDS_ID', payload);      
      Vue.set(state.records_id, payload.id, payload.data); },
    SET_RECORDS_DATA: function(state, payload) { 
      console.log('SET_RECORDS_DATA',payload);      
      Vue.set(state.records_data, payload.id, payload.data); },
    SET_RECORDS_STATE: function(state, payload) { 
      console.log('SET_RECORDS_STATE',payload);      
      Vue.set(state.records_state, payload.id, payload.state); },
    SET_RECORDS_ASSOC: function(state, payload) { Vue.set(state.records_assoc, payload.id, payload.table, payload.data); },

    SET_GLOBAL_STATE_PROCESS: function(state, payload) { state.global_state_process=payload },
    SET_GLOBAL_STATE_VERB: function(state, payload) { state.global_state_verb=payload },
    SET_GLOBAL_STATE_PAGEID: function(state, payload) { state.global_state_pageid=payload },

    POST_MESSAGE: function(state, payload) { state.message=payload.message; state.message_payload=payload.payload },
    CLEAR_MESSAGE: function(state) { state.message=""; state.message_payload="" },
  },
  modules: {
  },
  actions: {
    ssologin: async function(context, payload) {
      console.log('action login', payload);
      
      context.commit('SET_CONNECTION_ID', '');
      context.commit('SET_LOGIN_STATE', false);

      let resp=await apiclient.ssologin();
      return resp;
    },
    login: async function(context, payload) {
      console.log('action login', payload);
      
      context.commit('SET_CONNECTION_ID', '');
      context.commit('SET_LOGIN_STATE', false);

      try {
        let resp=await apiclient.login(payload.email, payload.myid_token);
        console.log(resp);
        if (resp.token) {
          context.commit('SET_CONNECTION_ID', resp.token);
          context.commit('SET_LOGIN_STATE', true)
          return true;  
        } else {
          return resp;
        }
      } catch(err) {
        console.log('login failed');
        return false;
      }
    },
    validate_login: async function(context) {
      console.log('test_validate');
      
      try {
        let resp=await apiclient.validate(context.getters.connection_id);
        console.log(resp);
        context.commit('SET_CONNECTION_ID', resp.token);
        context.commit('SET_LOGIN_STATE', true)
        return true;
      } catch(err) {
        console.log('login failed');
        console.log(err);
        
        return false;
      }
      
    },
    getConfigFromMeta: async function(context) {
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.getmeta(context.getters.connection_id);
        console.log(resp);
        context.commit( 'SET_GLOBAL_CONFIG', resp );

        context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('getConfigFromMeta failed');
        console.log(err);
        
        context.commit('SET_BUSY', false);
        return false;
      }
      
    },
    /*
        {
          "total": 1,
          "results": [
            {
              "id": "59621911167",
              "properties": {
                "hs_createdate": "2024-09-05T06:16:18.738Z",
                "hs_lastmodifieddate": "2024-09-05T06:16:18.738Z",
                "hs_object_id": "59621911167"
              },
              "createdAt": "2024-09-05T06:16:18.738Z",
              "updatedAt": "2024-09-05T06:16:18.738Z",
              "archived": false
            }
          ]
        }
    */

    load_records: async function(context, payload) {
      console.log('load_records', payload);

      let p=[];
      console.log(payload.cols);
      payload.cols.forEach( f => {
        if (f.fieldname!='id') p.push(f.fieldname);
      });

      let record=context.getters.getRecordData;
  
      if (payload.filters) {
        payload.filters.forEach( f => {
          if (f.recordField && record) f.value=record[f.recordField];
        });
      }

      /*
      let filters=[];
      if (payload.related) {
        filters.push(
          {
            "propertyName": "associations."+payload.tablename.slice(0, payload.tablename.length-1),
            "operator": "EQ",
            "value": this.$store.getters.getRecordId
          }
        );
      }
      */

      let res=await context.dispatch('search_records', { id: payload.id, object: payload.tablename, properties:p, filters: payload.filters, order: payload.order, groups: payload.groups});
      /*
      if (res.data) {
        res.data.results.forEach( (r) => {
          r.properties.id=r.id;
          this.records.push(r.properties);
        })
      }
      */
      return res;
    },
    search_records: async function(context, payload) {
      console.log('search_records', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.search(context.getters.connection_id, payload.object, payload.properties, payload.filters, payload.orders, payload.groups);
        console.log(resp);
        if (resp) {
          // resp.data.object=payload.object;
          // resp.data.filters=payload.filters;
          // resp.data.properties=payload.properties;
          // TODO: hubspot version for totals and pagination
          context.commit('SET_RECORDS_LIST', {id: payload.id, data: { records: resp.data.records, totalRecords: resp.data.totalSize, complete: resp.data.done, nextPage: resp.data.nextRecordsUrl } });
        }
        context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('search_records failed', payload);
        console.log(err);
        
        context.commit('SET_BUSY', false);
        return false;
      }
      
    },
    retrieve_record: async function(context, payload) {
      console.log('retrieve_record', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.retrieve(context.getters.connection_id, payload.object, payload.recordId, payload.properties, payload.associations);
        console.log('retrieve_record',resp);
        if (resp) {
          context.commit( 'SET_RECORDS_ID', { id: payload.id, data:resp.data.Id });
          context.commit( 'SET_RECORDS_DATA', { id: payload.id, data:resp.data });
          context.commit( 'SET_RECORDS_STATE', { id: payload.id, state:'READY' });
          /* vabbè andava e l'ho rotto TODO: SET_RECORDS_ASSOC
          if (resp.data.associations) {
            for (let assoc in resp.data.associations) {
              let ids=[];
              resp.data.associations[assoc].results.forEach( (r) => {
                ids.push({ "id":r.id, "type": r.type });
              })
              context.commit('SET_RECORDS_ASSOC', { table: assoc, data:ids} );
            }
          }
          */
        }
        context.commit('SET_BUSY', false);
        return resp;

      } catch(err) {
        console.log('search_records failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
      
    },
    batchread_records: async function(context, payload) {
      console.log('batchread_records', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.batchread(context.getters.connection_id, payload.object, payload.recordIds, payload.properties);
        console.log(resp);
        if (resp) {
          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('search_records failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    hydrate_records: async function(context, payload) {
      console.log('hydrate_records', payload);
      context.commit('SET_BUSY', true);

      let records=context.getters.getRecords(payload.id);
      let ids=[];
      records.forEach((r) => {
        ids.push(r.id);
      })
      try {
        let resp=await apiclient.batchread(context.getters.connection_id, payload.object, ids, payload.properties);
        console.log(resp);
        if (resp) {
          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('hydrate_records failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    create_record: async function(context, payload) {
      console.log('create_record', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.create(context.getters.connection_id, payload.object, payload.properties);
        console.log(resp);
        if (resp) {
//          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('create_record failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    update_record: async function(context, payload) {
      console.log('update_record', payload);
      context.commit('SET_BUSY', true);
      
      try {
        let resp=await apiclient.update(context.getters.connection_id, payload.object, payload.recordId, payload.properties);
        console.log(resp);
        if (resp) {
//          context.commit('SET_RECORDS_LIST', {id: payload.id, data: resp.data });
          context.commit('SET_BUSY', false);
        }
        return resp;

      } catch(err) {
        console.log('update_record failed', payload);
        console.log(err);
        context.commit('SET_BUSY', false);
        
        return false;
      }
    },
    logout: function(context) {
      console.log('User signed out.');
      localStorage.removeItem('salespaddy_id');
      context.commit('SET_CONNECTION_ID', '');
      context.commit('SET_LOGIN_STATE', false);
    },
  }
})
